enum Environment {
  development = 'development',
  production = 'production',
  test = 'test',
}

interface IConfig {
  env: Environment;
}

declare const CONFIG: IConfig;

export { Environment };
export const ENV = CONFIG.env;
